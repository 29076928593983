/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './portal-insurance.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as googleProtobuf006 from '@ngx-grpc/well-known-types';
import * as googleProtobuf007 from '@ngx-grpc/well-known-types';
import * as common_message008 from './common-message.pb';
import { GRPC_PORTAL_INSURANCE_GRPC_SERVICE_CLIENT_SETTINGS } from './portal-insurance.pbconf';
/**
 * Service client implementation for portal_insurance.PortalInsuranceGrpcService
 */
@Injectable({ providedIn: 'any' })
export class PortalInsuranceGrpcServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /portal_insurance.PortalInsuranceGrpcService/CreatePolicy
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreatePolicyResponse>>
     */
    createPolicy: (
      requestData: thisProto.CreatePolicyRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreatePolicyResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_insurance.PortalInsuranceGrpcService/CreatePolicy',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreatePolicyRequest,
        responseClass: thisProto.CreatePolicyResponse
      });
    },
    /**
     * Unary call: /portal_insurance.PortalInsuranceGrpcService/CommitPolicy
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CommitPolicyResponse>>
     */
    commitPolicy: (
      requestData: thisProto.CommitPolicyRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CommitPolicyResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_insurance.PortalInsuranceGrpcService/CommitPolicy',
        requestData,
        requestMetadata,
        requestClass: thisProto.CommitPolicyRequest,
        responseClass: thisProto.CommitPolicyResponse
      });
    },
    /**
     * Unary call: /portal_insurance.PortalInsuranceGrpcService/ListBeneficiaries
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListBeneficiariesResponse>>
     */
    listBeneficiaries: (
      requestData: thisProto.ListBeneficiariesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ListBeneficiariesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_insurance.PortalInsuranceGrpcService/ListBeneficiaries',
        requestData,
        requestMetadata,
        requestClass: thisProto.ListBeneficiariesRequest,
        responseClass: thisProto.ListBeneficiariesResponse
      });
    },
    /**
     * Unary call: /portal_insurance.PortalInsuranceGrpcService/UpdateBeneficiaries
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateBeneficiariesResponse>>
     */
    updateBeneficiaries: (
      requestData: thisProto.UpdateBeneficiariesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateBeneficiariesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/portal_insurance.PortalInsuranceGrpcService/UpdateBeneficiaries',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateBeneficiariesRequest,
        responseClass: thisProto.UpdateBeneficiariesResponse
      });
    },
    /**
     * Unary call: /portal_insurance.PortalInsuranceGrpcService/CancelPolicy
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CancelPolicyResponse>>
     */
    cancelPolicy: (
      requestData: thisProto.CancelPolicyRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CancelPolicyResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_insurance.PortalInsuranceGrpcService/CancelPolicy',
        requestData,
        requestMetadata,
        requestClass: thisProto.CancelPolicyRequest,
        responseClass: thisProto.CancelPolicyResponse
      });
    },
    /**
     * Unary call: /portal_insurance.PortalInsuranceGrpcService/GetPolicyDocument
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetPolicyDocumentResponse>>
     */
    getPolicyDocument: (
      requestData: thisProto.GetPolicyDocumentRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetPolicyDocumentResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_insurance.PortalInsuranceGrpcService/GetPolicyDocument',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetPolicyDocumentRequest,
        responseClass: thisProto.GetPolicyDocumentResponse
      });
    },
    /**
     * Unary call: /portal_insurance.PortalInsuranceGrpcService/ListInsuranceContracts
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListInsuranceContractsResponse>>
     */
    listInsuranceContracts: (
      requestData: googleProtobuf007.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ListInsuranceContractsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/portal_insurance.PortalInsuranceGrpcService/ListInsuranceContracts',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf007.Empty,
        responseClass: thisProto.ListInsuranceContractsResponse
      });
    },
    /**
     * Unary call: /portal_insurance.PortalInsuranceGrpcService/CalculateCost
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CalculateCostResponse>>
     */
    calculateCost: (
      requestData: thisProto.CalculateCostRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CalculateCostResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_insurance.PortalInsuranceGrpcService/CalculateCost',
        requestData,
        requestMetadata,
        requestClass: thisProto.CalculateCostRequest,
        responseClass: thisProto.CalculateCostResponse
      });
    },
    /**
     * Unary call: /portal_insurance.PortalInsuranceGrpcService/GetInsuranceContract
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.InsuranceContractDetail>>
     */
    getInsuranceContract: (
      requestData: thisProto.GetInsuranceContractRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message008.InsuranceContractDetail>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/portal_insurance.PortalInsuranceGrpcService/GetInsuranceContract',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetInsuranceContractRequest,
        responseClass: common_message008.InsuranceContractDetail
      });
    }
  };

  constructor(
    @Optional()
    @Inject(GRPC_PORTAL_INSURANCE_GRPC_SERVICE_CLIENT_SETTINGS)
    settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'portal_insurance.PortalInsuranceGrpcService',
      settings
    );
  }

  /**
   * Unary call @/portal_insurance.PortalInsuranceGrpcService/CreatePolicy
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreatePolicyResponse>
   */
  createPolicy(
    requestData: thisProto.CreatePolicyRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreatePolicyResponse> {
    return this.$raw
      .createPolicy(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_insurance.PortalInsuranceGrpcService/CommitPolicy
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CommitPolicyResponse>
   */
  commitPolicy(
    requestData: thisProto.CommitPolicyRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CommitPolicyResponse> {
    return this.$raw
      .commitPolicy(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_insurance.PortalInsuranceGrpcService/ListBeneficiaries
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListBeneficiariesResponse>
   */
  listBeneficiaries(
    requestData: thisProto.ListBeneficiariesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListBeneficiariesResponse> {
    return this.$raw
      .listBeneficiaries(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_insurance.PortalInsuranceGrpcService/UpdateBeneficiaries
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateBeneficiariesResponse>
   */
  updateBeneficiaries(
    requestData: thisProto.UpdateBeneficiariesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateBeneficiariesResponse> {
    return this.$raw
      .updateBeneficiaries(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_insurance.PortalInsuranceGrpcService/CancelPolicy
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CancelPolicyResponse>
   */
  cancelPolicy(
    requestData: thisProto.CancelPolicyRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CancelPolicyResponse> {
    return this.$raw
      .cancelPolicy(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_insurance.PortalInsuranceGrpcService/GetPolicyDocument
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetPolicyDocumentResponse>
   */
  getPolicyDocument(
    requestData: thisProto.GetPolicyDocumentRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetPolicyDocumentResponse> {
    return this.$raw
      .getPolicyDocument(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_insurance.PortalInsuranceGrpcService/ListInsuranceContracts
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListInsuranceContractsResponse>
   */
  listInsuranceContracts(
    requestData: googleProtobuf007.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListInsuranceContractsResponse> {
    return this.$raw
      .listInsuranceContracts(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_insurance.PortalInsuranceGrpcService/CalculateCost
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CalculateCostResponse>
   */
  calculateCost(
    requestData: thisProto.CalculateCostRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CalculateCostResponse> {
    return this.$raw
      .calculateCost(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_insurance.PortalInsuranceGrpcService/GetInsuranceContract
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.InsuranceContractDetail>
   */
  getInsuranceContract(
    requestData: thisProto.GetInsuranceContractRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.InsuranceContractDetail> {
    return this.$raw
      .getInsuranceContract(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
