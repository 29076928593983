/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
  uint8ArrayToBase64
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as googleProtobuf006 from '@ngx-grpc/well-known-types';
import * as googleProtobuf007 from '@ngx-grpc/well-known-types';
import * as common_message008 from './common-message.pb';
/**
 * Message implementation for portal_insurance.CreatePolicyRequest
 */
export class CreatePolicyRequest implements GrpcMessage {
  static id = 'portal_insurance.CreatePolicyRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreatePolicyRequest();
    CreatePolicyRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreatePolicyRequest) {
    _instance.productId = _instance.productId || 0;
    _instance.productPlanId = _instance.productPlanId || 0;
    _instance.subProductIds = _instance.subProductIds || [];
    _instance.address = _instance.address || '';
    _instance.communeId = _instance.communeId || 0;
    _instance.dateOfBirth = _instance.dateOfBirth || undefined;
    _instance.beneficiaries = _instance.beneficiaries || [];
    _instance.dpsData = _instance.dpsData || undefined;
    _instance.email = _instance.email || '';
    _instance.phone = _instance.phone || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreatePolicyRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.productId = _reader.readInt32();
          break;
        case 2:
          _instance.productPlanId = _reader.readInt32();
          break;
        case 3:
          (_instance.subProductIds = _instance.subProductIds || []).push(
            ...(_reader.readPackedInt32() || [])
          );
          break;
        case 4:
          _instance.address = _reader.readString();
          break;
        case 5:
          _instance.communeId = _reader.readInt32();
          break;
        case 6:
          _instance.dateOfBirth = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.dateOfBirth,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          const messageInitializer7 = new BeneficiaryDetail();
          _reader.readMessage(
            messageInitializer7,
            BeneficiaryDetail.deserializeBinaryFromReader
          );
          (_instance.beneficiaries = _instance.beneficiaries || []).push(
            messageInitializer7
          );
          break;
        case 8:
          _instance.dpsData = new googleProtobuf006.Struct();
          _reader.readMessage(
            _instance.dpsData,
            googleProtobuf006.Struct.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.email = _reader.readString();
          break;
        case 10:
          _instance.phone = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreatePolicyRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreatePolicyRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.productId) {
      _writer.writeInt32(1, _instance.productId);
    }
    if (_instance.productPlanId) {
      _writer.writeInt32(2, _instance.productPlanId);
    }
    if (_instance.subProductIds && _instance.subProductIds.length) {
      _writer.writePackedInt32(3, _instance.subProductIds);
    }
    if (_instance.address) {
      _writer.writeString(4, _instance.address);
    }
    if (_instance.communeId) {
      _writer.writeInt32(5, _instance.communeId);
    }
    if (_instance.dateOfBirth) {
      _writer.writeMessage(
        6,
        _instance.dateOfBirth as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.beneficiaries && _instance.beneficiaries.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.beneficiaries as any,
        BeneficiaryDetail.serializeBinaryToWriter
      );
    }
    if (_instance.dpsData) {
      _writer.writeMessage(
        8,
        _instance.dpsData as any,
        googleProtobuf006.Struct.serializeBinaryToWriter
      );
    }
    if (_instance.email) {
      _writer.writeString(9, _instance.email);
    }
    if (_instance.phone) {
      _writer.writeString(10, _instance.phone);
    }
  }

  private _productId: number;
  private _productPlanId: number;
  private _subProductIds: number[];
  private _address: string;
  private _communeId: number;
  private _dateOfBirth?: googleProtobuf001.Timestamp;
  private _beneficiaries?: BeneficiaryDetail[];
  private _dpsData?: googleProtobuf006.Struct;
  private _email: string;
  private _phone: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreatePolicyRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreatePolicyRequest.AsObject>) {
    _value = _value || {};
    this.productId = _value.productId;
    this.productPlanId = _value.productPlanId;
    this.subProductIds = (_value.subProductIds || []).slice();
    this.address = _value.address;
    this.communeId = _value.communeId;
    this.dateOfBirth = _value.dateOfBirth
      ? new googleProtobuf001.Timestamp(_value.dateOfBirth)
      : undefined;
    this.beneficiaries = (_value.beneficiaries || []).map(
      m => new BeneficiaryDetail(m)
    );
    this.dpsData = _value.dpsData
      ? new googleProtobuf006.Struct(_value.dpsData)
      : undefined;
    this.email = _value.email;
    this.phone = _value.phone;
    CreatePolicyRequest.refineValues(this);
  }
  get productId(): number {
    return this._productId;
  }
  set productId(value: number) {
    this._productId = value;
  }
  get productPlanId(): number {
    return this._productPlanId;
  }
  set productPlanId(value: number) {
    this._productPlanId = value;
  }
  get subProductIds(): number[] {
    return this._subProductIds;
  }
  set subProductIds(value: number[]) {
    this._subProductIds = value;
  }
  get address(): string {
    return this._address;
  }
  set address(value: string) {
    this._address = value;
  }
  get communeId(): number {
    return this._communeId;
  }
  set communeId(value: number) {
    this._communeId = value;
  }
  get dateOfBirth(): googleProtobuf001.Timestamp | undefined {
    return this._dateOfBirth;
  }
  set dateOfBirth(value: googleProtobuf001.Timestamp | undefined) {
    this._dateOfBirth = value;
  }
  get beneficiaries(): BeneficiaryDetail[] | undefined {
    return this._beneficiaries;
  }
  set beneficiaries(value: BeneficiaryDetail[] | undefined) {
    this._beneficiaries = value;
  }
  get dpsData(): googleProtobuf006.Struct | undefined {
    return this._dpsData;
  }
  set dpsData(value: googleProtobuf006.Struct | undefined) {
    this._dpsData = value;
  }
  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
  }
  get phone(): string {
    return this._phone;
  }
  set phone(value: string) {
    this._phone = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreatePolicyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreatePolicyRequest.AsObject {
    return {
      productId: this.productId,
      productPlanId: this.productPlanId,
      subProductIds: (this.subProductIds || []).slice(),
      address: this.address,
      communeId: this.communeId,
      dateOfBirth: this.dateOfBirth ? this.dateOfBirth.toObject() : undefined,
      beneficiaries: (this.beneficiaries || []).map(m => m.toObject()),
      dpsData: this.dpsData ? this.dpsData.toObject() : undefined,
      email: this.email,
      phone: this.phone
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreatePolicyRequest.AsProtobufJSON {
    return {
      productId: this.productId,
      productPlanId: this.productPlanId,
      subProductIds: (this.subProductIds || []).slice(),
      address: this.address,
      communeId: this.communeId,
      dateOfBirth: this.dateOfBirth
        ? this.dateOfBirth.toProtobufJSON(options)
        : null,
      beneficiaries: (this.beneficiaries || []).map(m =>
        m.toProtobufJSON(options)
      ),
      dpsData: this.dpsData ? this.dpsData.toProtobufJSON(options) : null,
      email: this.email,
      phone: this.phone
    };
  }
}
export module CreatePolicyRequest {
  /**
   * Standard JavaScript object representation for CreatePolicyRequest
   */
  export interface AsObject {
    productId: number;
    productPlanId: number;
    subProductIds: number[];
    address: string;
    communeId: number;
    dateOfBirth?: googleProtobuf001.Timestamp.AsObject;
    beneficiaries?: BeneficiaryDetail.AsObject[];
    dpsData?: googleProtobuf006.Struct.AsObject;
    email: string;
    phone: string;
  }

  /**
   * Protobuf JSON representation for CreatePolicyRequest
   */
  export interface AsProtobufJSON {
    productId: number;
    productPlanId: number;
    subProductIds: number[];
    address: string;
    communeId: number;
    dateOfBirth: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    beneficiaries: BeneficiaryDetail.AsProtobufJSON[] | null;
    dpsData: googleProtobuf006.Struct.AsProtobufJSON | null;
    email: string;
    phone: string;
  }
}

/**
 * Message implementation for portal_insurance.CalculateCostRequest
 */
export class CalculateCostRequest implements GrpcMessage {
  static id = 'portal_insurance.CalculateCostRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CalculateCostRequest();
    CalculateCostRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CalculateCostRequest) {
    _instance.productId = _instance.productId || 0;
    _instance.dateOfBirth = _instance.dateOfBirth || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CalculateCostRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.productId = _reader.readInt32();
          break;
        case 12:
          _instance.dateOfBirth = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.dateOfBirth,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CalculateCostRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CalculateCostRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.productId) {
      _writer.writeInt32(1, _instance.productId);
    }
    if (_instance.dateOfBirth) {
      _writer.writeMessage(
        12,
        _instance.dateOfBirth as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _productId: number;
  private _dateOfBirth?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CalculateCostRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CalculateCostRequest.AsObject>) {
    _value = _value || {};
    this.productId = _value.productId;
    this.dateOfBirth = _value.dateOfBirth
      ? new googleProtobuf001.Timestamp(_value.dateOfBirth)
      : undefined;
    CalculateCostRequest.refineValues(this);
  }
  get productId(): number {
    return this._productId;
  }
  set productId(value: number) {
    this._productId = value;
  }
  get dateOfBirth(): googleProtobuf001.Timestamp | undefined {
    return this._dateOfBirth;
  }
  set dateOfBirth(value: googleProtobuf001.Timestamp | undefined) {
    this._dateOfBirth = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CalculateCostRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CalculateCostRequest.AsObject {
    return {
      productId: this.productId,
      dateOfBirth: this.dateOfBirth ? this.dateOfBirth.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CalculateCostRequest.AsProtobufJSON {
    return {
      productId: this.productId,
      dateOfBirth: this.dateOfBirth
        ? this.dateOfBirth.toProtobufJSON(options)
        : null
    };
  }
}
export module CalculateCostRequest {
  /**
   * Standard JavaScript object representation for CalculateCostRequest
   */
  export interface AsObject {
    productId: number;
    dateOfBirth?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for CalculateCostRequest
   */
  export interface AsProtobufJSON {
    productId: number;
    dateOfBirth: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for portal_insurance.CalculateCostResponse
 */
export class CalculateCostResponse implements GrpcMessage {
  static id = 'portal_insurance.CalculateCostResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CalculateCostResponse();
    CalculateCostResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CalculateCostResponse) {
    _instance.productId = _instance.productId || 0;
    _instance.plans = _instance.plans || [];
    _instance.subProductPlan = _instance.subProductPlan || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CalculateCostResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.productId = _reader.readInt32();
          break;
        case 2:
          const messageInitializer2 = new PlanCost();
          _reader.readMessage(
            messageInitializer2,
            PlanCost.deserializeBinaryFromReader
          );
          (_instance.plans = _instance.plans || []).push(messageInitializer2);
          break;
        case 3:
          const messageInitializer3 = new SubProductPlan();
          _reader.readMessage(
            messageInitializer3,
            SubProductPlan.deserializeBinaryFromReader
          );
          (_instance.subProductPlan = _instance.subProductPlan || []).push(
            messageInitializer3
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CalculateCostResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CalculateCostResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.productId) {
      _writer.writeInt32(1, _instance.productId);
    }
    if (_instance.plans && _instance.plans.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.plans as any,
        PlanCost.serializeBinaryToWriter
      );
    }
    if (_instance.subProductPlan && _instance.subProductPlan.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.subProductPlan as any,
        SubProductPlan.serializeBinaryToWriter
      );
    }
  }

  private _productId: number;
  private _plans?: PlanCost[];
  private _subProductPlan?: SubProductPlan[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CalculateCostResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CalculateCostResponse.AsObject>) {
    _value = _value || {};
    this.productId = _value.productId;
    this.plans = (_value.plans || []).map(m => new PlanCost(m));
    this.subProductPlan = (_value.subProductPlan || []).map(
      m => new SubProductPlan(m)
    );
    CalculateCostResponse.refineValues(this);
  }
  get productId(): number {
    return this._productId;
  }
  set productId(value: number) {
    this._productId = value;
  }
  get plans(): PlanCost[] | undefined {
    return this._plans;
  }
  set plans(value: PlanCost[] | undefined) {
    this._plans = value;
  }
  get subProductPlan(): SubProductPlan[] | undefined {
    return this._subProductPlan;
  }
  set subProductPlan(value: SubProductPlan[] | undefined) {
    this._subProductPlan = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CalculateCostResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CalculateCostResponse.AsObject {
    return {
      productId: this.productId,
      plans: (this.plans || []).map(m => m.toObject()),
      subProductPlan: (this.subProductPlan || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CalculateCostResponse.AsProtobufJSON {
    return {
      productId: this.productId,
      plans: (this.plans || []).map(m => m.toProtobufJSON(options)),
      subProductPlan: (this.subProductPlan || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module CalculateCostResponse {
  /**
   * Standard JavaScript object representation for CalculateCostResponse
   */
  export interface AsObject {
    productId: number;
    plans?: PlanCost.AsObject[];
    subProductPlan?: SubProductPlan.AsObject[];
  }

  /**
   * Protobuf JSON representation for CalculateCostResponse
   */
  export interface AsProtobufJSON {
    productId: number;
    plans: PlanCost.AsProtobufJSON[] | null;
    subProductPlan: SubProductPlan.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for portal_insurance.PlanCost
 */
export class PlanCost implements GrpcMessage {
  static id = 'portal_insurance.PlanCost';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PlanCost();
    PlanCost.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PlanCost) {
    _instance.planId = _instance.planId || 0;
    _instance.costClp = _instance.costClp || 0;
    _instance.costUf = _instance.costUf || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PlanCost,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.planId = _reader.readInt32();
          break;
        case 2:
          _instance.costClp = _reader.readInt32();
          break;
        case 3:
          _instance.costUf = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    PlanCost.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: PlanCost, _writer: BinaryWriter) {
    if (_instance.planId) {
      _writer.writeInt32(1, _instance.planId);
    }
    if (_instance.costClp) {
      _writer.writeInt32(2, _instance.costClp);
    }
    if (_instance.costUf) {
      _writer.writeInt32(3, _instance.costUf);
    }
  }

  private _planId: number;
  private _costClp: number;
  private _costUf: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PlanCost to deeply clone from
   */
  constructor(_value?: RecursivePartial<PlanCost.AsObject>) {
    _value = _value || {};
    this.planId = _value.planId;
    this.costClp = _value.costClp;
    this.costUf = _value.costUf;
    PlanCost.refineValues(this);
  }
  get planId(): number {
    return this._planId;
  }
  set planId(value: number) {
    this._planId = value;
  }
  get costClp(): number {
    return this._costClp;
  }
  set costClp(value: number) {
    this._costClp = value;
  }
  get costUf(): number {
    return this._costUf;
  }
  set costUf(value: number) {
    this._costUf = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PlanCost.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PlanCost.AsObject {
    return {
      planId: this.planId,
      costClp: this.costClp,
      costUf: this.costUf
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PlanCost.AsProtobufJSON {
    return {
      planId: this.planId,
      costClp: this.costClp,
      costUf: this.costUf
    };
  }
}
export module PlanCost {
  /**
   * Standard JavaScript object representation for PlanCost
   */
  export interface AsObject {
    planId: number;
    costClp: number;
    costUf: number;
  }

  /**
   * Protobuf JSON representation for PlanCost
   */
  export interface AsProtobufJSON {
    planId: number;
    costClp: number;
    costUf: number;
  }
}

/**
 * Message implementation for portal_insurance.SubProductPlan
 */
export class SubProductPlan implements GrpcMessage {
  static id = 'portal_insurance.SubProductPlan';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SubProductPlan();
    SubProductPlan.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SubProductPlan) {
    _instance.subProductId = _instance.subProductId || 0;
    _instance.plans = _instance.plans || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SubProductPlan,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.subProductId = _reader.readInt32();
          break;
        case 2:
          const messageInitializer2 = new PlanCost();
          _reader.readMessage(
            messageInitializer2,
            PlanCost.deserializeBinaryFromReader
          );
          (_instance.plans = _instance.plans || []).push(messageInitializer2);
          break;
        default:
          _reader.skipField();
      }
    }

    SubProductPlan.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SubProductPlan,
    _writer: BinaryWriter
  ) {
    if (_instance.subProductId) {
      _writer.writeInt32(1, _instance.subProductId);
    }
    if (_instance.plans && _instance.plans.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.plans as any,
        PlanCost.serializeBinaryToWriter
      );
    }
  }

  private _subProductId: number;
  private _plans?: PlanCost[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SubProductPlan to deeply clone from
   */
  constructor(_value?: RecursivePartial<SubProductPlan.AsObject>) {
    _value = _value || {};
    this.subProductId = _value.subProductId;
    this.plans = (_value.plans || []).map(m => new PlanCost(m));
    SubProductPlan.refineValues(this);
  }
  get subProductId(): number {
    return this._subProductId;
  }
  set subProductId(value: number) {
    this._subProductId = value;
  }
  get plans(): PlanCost[] | undefined {
    return this._plans;
  }
  set plans(value: PlanCost[] | undefined) {
    this._plans = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SubProductPlan.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SubProductPlan.AsObject {
    return {
      subProductId: this.subProductId,
      plans: (this.plans || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SubProductPlan.AsProtobufJSON {
    return {
      subProductId: this.subProductId,
      plans: (this.plans || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module SubProductPlan {
  /**
   * Standard JavaScript object representation for SubProductPlan
   */
  export interface AsObject {
    subProductId: number;
    plans?: PlanCost.AsObject[];
  }

  /**
   * Protobuf JSON representation for SubProductPlan
   */
  export interface AsProtobufJSON {
    subProductId: number;
    plans: PlanCost.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for portal_insurance.CreatePolicyResponse
 */
export class CreatePolicyResponse implements GrpcMessage {
  static id = 'portal_insurance.CreatePolicyResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreatePolicyResponse();
    CreatePolicyResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreatePolicyResponse) {
    _instance.insuranceContractId = _instance.insuranceContractId || 0;
    _instance.status = _instance.status || '';
    _instance.urlPayment = _instance.urlPayment || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreatePolicyResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.insuranceContractId = _reader.readInt32();
          break;
        case 2:
          _instance.status = _reader.readString();
          break;
        case 3:
          _instance.urlPayment = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreatePolicyResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreatePolicyResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.insuranceContractId) {
      _writer.writeInt32(1, _instance.insuranceContractId);
    }
    if (_instance.status) {
      _writer.writeString(2, _instance.status);
    }
    if (_instance.urlPayment) {
      _writer.writeString(3, _instance.urlPayment);
    }
  }

  private _insuranceContractId: number;
  private _status: string;
  private _urlPayment: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreatePolicyResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreatePolicyResponse.AsObject>) {
    _value = _value || {};
    this.insuranceContractId = _value.insuranceContractId;
    this.status = _value.status;
    this.urlPayment = _value.urlPayment;
    CreatePolicyResponse.refineValues(this);
  }
  get insuranceContractId(): number {
    return this._insuranceContractId;
  }
  set insuranceContractId(value: number) {
    this._insuranceContractId = value;
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }
  get urlPayment(): string {
    return this._urlPayment;
  }
  set urlPayment(value: string) {
    this._urlPayment = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreatePolicyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreatePolicyResponse.AsObject {
    return {
      insuranceContractId: this.insuranceContractId,
      status: this.status,
      urlPayment: this.urlPayment
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreatePolicyResponse.AsProtobufJSON {
    return {
      insuranceContractId: this.insuranceContractId,
      status: this.status,
      urlPayment: this.urlPayment
    };
  }
}
export module CreatePolicyResponse {
  /**
   * Standard JavaScript object representation for CreatePolicyResponse
   */
  export interface AsObject {
    insuranceContractId: number;
    status: string;
    urlPayment: string;
  }

  /**
   * Protobuf JSON representation for CreatePolicyResponse
   */
  export interface AsProtobufJSON {
    insuranceContractId: number;
    status: string;
    urlPayment: string;
  }
}

/**
 * Message implementation for portal_insurance.CommitPolicyRequest
 */
export class CommitPolicyRequest implements GrpcMessage {
  static id = 'portal_insurance.CommitPolicyRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CommitPolicyRequest();
    CommitPolicyRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CommitPolicyRequest) {
    _instance.insuranceContractId = _instance.insuranceContractId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CommitPolicyRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.insuranceContractId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    CommitPolicyRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CommitPolicyRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.insuranceContractId) {
      _writer.writeInt32(1, _instance.insuranceContractId);
    }
  }

  private _insuranceContractId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CommitPolicyRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CommitPolicyRequest.AsObject>) {
    _value = _value || {};
    this.insuranceContractId = _value.insuranceContractId;
    CommitPolicyRequest.refineValues(this);
  }
  get insuranceContractId(): number {
    return this._insuranceContractId;
  }
  set insuranceContractId(value: number) {
    this._insuranceContractId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CommitPolicyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CommitPolicyRequest.AsObject {
    return {
      insuranceContractId: this.insuranceContractId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CommitPolicyRequest.AsProtobufJSON {
    return {
      insuranceContractId: this.insuranceContractId
    };
  }
}
export module CommitPolicyRequest {
  /**
   * Standard JavaScript object representation for CommitPolicyRequest
   */
  export interface AsObject {
    insuranceContractId: number;
  }

  /**
   * Protobuf JSON representation for CommitPolicyRequest
   */
  export interface AsProtobufJSON {
    insuranceContractId: number;
  }
}

/**
 * Message implementation for portal_insurance.CommitPolicyResponse
 */
export class CommitPolicyResponse implements GrpcMessage {
  static id = 'portal_insurance.CommitPolicyResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CommitPolicyResponse();
    CommitPolicyResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CommitPolicyResponse) {
    _instance.insuranceContractId = _instance.insuranceContractId || 0;
    _instance.status = _instance.status || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CommitPolicyResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.insuranceContractId = _reader.readInt32();
          break;
        case 2:
          _instance.status = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CommitPolicyResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CommitPolicyResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.insuranceContractId) {
      _writer.writeInt32(1, _instance.insuranceContractId);
    }
    if (_instance.status) {
      _writer.writeString(2, _instance.status);
    }
  }

  private _insuranceContractId: number;
  private _status: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CommitPolicyResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CommitPolicyResponse.AsObject>) {
    _value = _value || {};
    this.insuranceContractId = _value.insuranceContractId;
    this.status = _value.status;
    CommitPolicyResponse.refineValues(this);
  }
  get insuranceContractId(): number {
    return this._insuranceContractId;
  }
  set insuranceContractId(value: number) {
    this._insuranceContractId = value;
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CommitPolicyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CommitPolicyResponse.AsObject {
    return {
      insuranceContractId: this.insuranceContractId,
      status: this.status
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CommitPolicyResponse.AsProtobufJSON {
    return {
      insuranceContractId: this.insuranceContractId,
      status: this.status
    };
  }
}
export module CommitPolicyResponse {
  /**
   * Standard JavaScript object representation for CommitPolicyResponse
   */
  export interface AsObject {
    insuranceContractId: number;
    status: string;
  }

  /**
   * Protobuf JSON representation for CommitPolicyResponse
   */
  export interface AsProtobufJSON {
    insuranceContractId: number;
    status: string;
  }
}

/**
 * Message implementation for portal_insurance.ListBeneficiariesRequest
 */
export class ListBeneficiariesRequest implements GrpcMessage {
  static id = 'portal_insurance.ListBeneficiariesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListBeneficiariesRequest();
    ListBeneficiariesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListBeneficiariesRequest) {
    _instance.insuranceContractId = _instance.insuranceContractId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListBeneficiariesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.insuranceContractId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListBeneficiariesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListBeneficiariesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.insuranceContractId) {
      _writer.writeInt32(1, _instance.insuranceContractId);
    }
  }

  private _insuranceContractId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListBeneficiariesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListBeneficiariesRequest.AsObject>) {
    _value = _value || {};
    this.insuranceContractId = _value.insuranceContractId;
    ListBeneficiariesRequest.refineValues(this);
  }
  get insuranceContractId(): number {
    return this._insuranceContractId;
  }
  set insuranceContractId(value: number) {
    this._insuranceContractId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListBeneficiariesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListBeneficiariesRequest.AsObject {
    return {
      insuranceContractId: this.insuranceContractId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListBeneficiariesRequest.AsProtobufJSON {
    return {
      insuranceContractId: this.insuranceContractId
    };
  }
}
export module ListBeneficiariesRequest {
  /**
   * Standard JavaScript object representation for ListBeneficiariesRequest
   */
  export interface AsObject {
    insuranceContractId: number;
  }

  /**
   * Protobuf JSON representation for ListBeneficiariesRequest
   */
  export interface AsProtobufJSON {
    insuranceContractId: number;
  }
}

/**
 * Message implementation for portal_insurance.UpdateBeneficiariesRequest
 */
export class UpdateBeneficiariesRequest implements GrpcMessage {
  static id = 'portal_insurance.UpdateBeneficiariesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateBeneficiariesRequest();
    UpdateBeneficiariesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateBeneficiariesRequest) {
    _instance.insuranceContractId = _instance.insuranceContractId || 0;
    _instance.beneficiaries = _instance.beneficiaries || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateBeneficiariesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.insuranceContractId = _reader.readInt32();
          break;
        case 2:
          const messageInitializer2 = new BeneficiaryDetail();
          _reader.readMessage(
            messageInitializer2,
            BeneficiaryDetail.deserializeBinaryFromReader
          );
          (_instance.beneficiaries = _instance.beneficiaries || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateBeneficiariesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateBeneficiariesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.insuranceContractId) {
      _writer.writeInt32(1, _instance.insuranceContractId);
    }
    if (_instance.beneficiaries && _instance.beneficiaries.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.beneficiaries as any,
        BeneficiaryDetail.serializeBinaryToWriter
      );
    }
  }

  private _insuranceContractId: number;
  private _beneficiaries?: BeneficiaryDetail[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateBeneficiariesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateBeneficiariesRequest.AsObject>) {
    _value = _value || {};
    this.insuranceContractId = _value.insuranceContractId;
    this.beneficiaries = (_value.beneficiaries || []).map(
      m => new BeneficiaryDetail(m)
    );
    UpdateBeneficiariesRequest.refineValues(this);
  }
  get insuranceContractId(): number {
    return this._insuranceContractId;
  }
  set insuranceContractId(value: number) {
    this._insuranceContractId = value;
  }
  get beneficiaries(): BeneficiaryDetail[] | undefined {
    return this._beneficiaries;
  }
  set beneficiaries(value: BeneficiaryDetail[] | undefined) {
    this._beneficiaries = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateBeneficiariesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateBeneficiariesRequest.AsObject {
    return {
      insuranceContractId: this.insuranceContractId,
      beneficiaries: (this.beneficiaries || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateBeneficiariesRequest.AsProtobufJSON {
    return {
      insuranceContractId: this.insuranceContractId,
      beneficiaries: (this.beneficiaries || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module UpdateBeneficiariesRequest {
  /**
   * Standard JavaScript object representation for UpdateBeneficiariesRequest
   */
  export interface AsObject {
    insuranceContractId: number;
    beneficiaries?: BeneficiaryDetail.AsObject[];
  }

  /**
   * Protobuf JSON representation for UpdateBeneficiariesRequest
   */
  export interface AsProtobufJSON {
    insuranceContractId: number;
    beneficiaries: BeneficiaryDetail.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for portal_insurance.ListBeneficiariesResponse
 */
export class ListBeneficiariesResponse implements GrpcMessage {
  static id = 'portal_insurance.ListBeneficiariesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListBeneficiariesResponse();
    ListBeneficiariesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListBeneficiariesResponse) {
    _instance.beneficiaries = _instance.beneficiaries || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListBeneficiariesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new BeneficiaryDetail();
          _reader.readMessage(
            messageInitializer1,
            BeneficiaryDetail.deserializeBinaryFromReader
          );
          (_instance.beneficiaries = _instance.beneficiaries || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListBeneficiariesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListBeneficiariesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.beneficiaries && _instance.beneficiaries.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.beneficiaries as any,
        BeneficiaryDetail.serializeBinaryToWriter
      );
    }
  }

  private _beneficiaries?: BeneficiaryDetail[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListBeneficiariesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListBeneficiariesResponse.AsObject>) {
    _value = _value || {};
    this.beneficiaries = (_value.beneficiaries || []).map(
      m => new BeneficiaryDetail(m)
    );
    ListBeneficiariesResponse.refineValues(this);
  }
  get beneficiaries(): BeneficiaryDetail[] | undefined {
    return this._beneficiaries;
  }
  set beneficiaries(value: BeneficiaryDetail[] | undefined) {
    this._beneficiaries = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListBeneficiariesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListBeneficiariesResponse.AsObject {
    return {
      beneficiaries: (this.beneficiaries || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListBeneficiariesResponse.AsProtobufJSON {
    return {
      beneficiaries: (this.beneficiaries || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module ListBeneficiariesResponse {
  /**
   * Standard JavaScript object representation for ListBeneficiariesResponse
   */
  export interface AsObject {
    beneficiaries?: BeneficiaryDetail.AsObject[];
  }

  /**
   * Protobuf JSON representation for ListBeneficiariesResponse
   */
  export interface AsProtobufJSON {
    beneficiaries: BeneficiaryDetail.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for portal_insurance.UpdateBeneficiariesResponse
 */
export class UpdateBeneficiariesResponse implements GrpcMessage {
  static id = 'portal_insurance.UpdateBeneficiariesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateBeneficiariesResponse();
    UpdateBeneficiariesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateBeneficiariesResponse) {
    _instance.status = _instance.status || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateBeneficiariesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateBeneficiariesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateBeneficiariesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeString(1, _instance.status);
    }
  }

  private _status: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateBeneficiariesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateBeneficiariesResponse.AsObject>) {
    _value = _value || {};
    this.status = _value.status;
    UpdateBeneficiariesResponse.refineValues(this);
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateBeneficiariesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateBeneficiariesResponse.AsObject {
    return {
      status: this.status
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateBeneficiariesResponse.AsProtobufJSON {
    return {
      status: this.status
    };
  }
}
export module UpdateBeneficiariesResponse {
  /**
   * Standard JavaScript object representation for UpdateBeneficiariesResponse
   */
  export interface AsObject {
    status: string;
  }

  /**
   * Protobuf JSON representation for UpdateBeneficiariesResponse
   */
  export interface AsProtobufJSON {
    status: string;
  }
}

/**
 * Message implementation for portal_insurance.BeneficiaryDetail
 */
export class BeneficiaryDetail implements GrpcMessage {
  static id = 'portal_insurance.BeneficiaryDetail';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BeneficiaryDetail();
    BeneficiaryDetail.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BeneficiaryDetail) {
    _instance.id = _instance.id || undefined;
    _instance.firstName = _instance.firstName || '';
    _instance.lastName = _instance.lastName || '';
    _instance.relationship = _instance.relationship || '';
    _instance.percentage = _instance.percentage || 0;
    _instance.personalId = _instance.personalId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BeneficiaryDetail,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.id,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.firstName = _reader.readString();
          break;
        case 3:
          _instance.lastName = _reader.readString();
          break;
        case 4:
          _instance.relationship = _reader.readString();
          break;
        case 5:
          _instance.percentage = _reader.readInt32();
          break;
        case 6:
          _instance.personalId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    BeneficiaryDetail.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BeneficiaryDetail,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeMessage(
        1,
        _instance.id as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.firstName) {
      _writer.writeString(2, _instance.firstName);
    }
    if (_instance.lastName) {
      _writer.writeString(3, _instance.lastName);
    }
    if (_instance.relationship) {
      _writer.writeString(4, _instance.relationship);
    }
    if (_instance.percentage) {
      _writer.writeInt32(5, _instance.percentage);
    }
    if (_instance.personalId) {
      _writer.writeString(6, _instance.personalId);
    }
  }

  private _id?: googleProtobuf000.Int32Value;
  private _firstName: string;
  private _lastName: string;
  private _relationship: string;
  private _percentage: number;
  private _personalId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BeneficiaryDetail to deeply clone from
   */
  constructor(_value?: RecursivePartial<BeneficiaryDetail.AsObject>) {
    _value = _value || {};
    this.id = _value.id
      ? new googleProtobuf000.Int32Value(_value.id)
      : undefined;
    this.firstName = _value.firstName;
    this.lastName = _value.lastName;
    this.relationship = _value.relationship;
    this.percentage = _value.percentage;
    this.personalId = _value.personalId;
    BeneficiaryDetail.refineValues(this);
  }
  get id(): googleProtobuf000.Int32Value | undefined {
    return this._id;
  }
  set id(value: googleProtobuf000.Int32Value | undefined) {
    this._id = value;
  }
  get firstName(): string {
    return this._firstName;
  }
  set firstName(value: string) {
    this._firstName = value;
  }
  get lastName(): string {
    return this._lastName;
  }
  set lastName(value: string) {
    this._lastName = value;
  }
  get relationship(): string {
    return this._relationship;
  }
  set relationship(value: string) {
    this._relationship = value;
  }
  get percentage(): number {
    return this._percentage;
  }
  set percentage(value: number) {
    this._percentage = value;
  }
  get personalId(): string {
    return this._personalId;
  }
  set personalId(value: string) {
    this._personalId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BeneficiaryDetail.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BeneficiaryDetail.AsObject {
    return {
      id: this.id ? this.id.toObject() : undefined,
      firstName: this.firstName,
      lastName: this.lastName,
      relationship: this.relationship,
      percentage: this.percentage,
      personalId: this.personalId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BeneficiaryDetail.AsProtobufJSON {
    return {
      id: this.id ? this.id.toProtobufJSON(options) : null,
      firstName: this.firstName,
      lastName: this.lastName,
      relationship: this.relationship,
      percentage: this.percentage,
      personalId: this.personalId
    };
  }
}
export module BeneficiaryDetail {
  /**
   * Standard JavaScript object representation for BeneficiaryDetail
   */
  export interface AsObject {
    id?: googleProtobuf000.Int32Value.AsObject;
    firstName: string;
    lastName: string;
    relationship: string;
    percentage: number;
    personalId: string;
  }

  /**
   * Protobuf JSON representation for BeneficiaryDetail
   */
  export interface AsProtobufJSON {
    id: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    firstName: string;
    lastName: string;
    relationship: string;
    percentage: number;
    personalId: string;
  }
}

/**
 * Message implementation for portal_insurance.CancelPolicyRequest
 */
export class CancelPolicyRequest implements GrpcMessage {
  static id = 'portal_insurance.CancelPolicyRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CancelPolicyRequest();
    CancelPolicyRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CancelPolicyRequest) {
    _instance.insuranceContractId = _instance.insuranceContractId || 0;
    _instance.cancelReason = _instance.cancelReason || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CancelPolicyRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.insuranceContractId = _reader.readInt32();
          break;
        case 2:
          _instance.cancelReason = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CancelPolicyRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CancelPolicyRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.insuranceContractId) {
      _writer.writeInt32(1, _instance.insuranceContractId);
    }
    if (_instance.cancelReason) {
      _writer.writeString(2, _instance.cancelReason);
    }
  }

  private _insuranceContractId: number;
  private _cancelReason: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CancelPolicyRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CancelPolicyRequest.AsObject>) {
    _value = _value || {};
    this.insuranceContractId = _value.insuranceContractId;
    this.cancelReason = _value.cancelReason;
    CancelPolicyRequest.refineValues(this);
  }
  get insuranceContractId(): number {
    return this._insuranceContractId;
  }
  set insuranceContractId(value: number) {
    this._insuranceContractId = value;
  }
  get cancelReason(): string {
    return this._cancelReason;
  }
  set cancelReason(value: string) {
    this._cancelReason = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CancelPolicyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CancelPolicyRequest.AsObject {
    return {
      insuranceContractId: this.insuranceContractId,
      cancelReason: this.cancelReason
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CancelPolicyRequest.AsProtobufJSON {
    return {
      insuranceContractId: this.insuranceContractId,
      cancelReason: this.cancelReason
    };
  }
}
export module CancelPolicyRequest {
  /**
   * Standard JavaScript object representation for CancelPolicyRequest
   */
  export interface AsObject {
    insuranceContractId: number;
    cancelReason: string;
  }

  /**
   * Protobuf JSON representation for CancelPolicyRequest
   */
  export interface AsProtobufJSON {
    insuranceContractId: number;
    cancelReason: string;
  }
}

/**
 * Message implementation for portal_insurance.CancelPolicyResponse
 */
export class CancelPolicyResponse implements GrpcMessage {
  static id = 'portal_insurance.CancelPolicyResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CancelPolicyResponse();
    CancelPolicyResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CancelPolicyResponse) {
    _instance.status = _instance.status || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CancelPolicyResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CancelPolicyResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CancelPolicyResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeString(1, _instance.status);
    }
  }

  private _status: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CancelPolicyResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CancelPolicyResponse.AsObject>) {
    _value = _value || {};
    this.status = _value.status;
    CancelPolicyResponse.refineValues(this);
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CancelPolicyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CancelPolicyResponse.AsObject {
    return {
      status: this.status
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CancelPolicyResponse.AsProtobufJSON {
    return {
      status: this.status
    };
  }
}
export module CancelPolicyResponse {
  /**
   * Standard JavaScript object representation for CancelPolicyResponse
   */
  export interface AsObject {
    status: string;
  }

  /**
   * Protobuf JSON representation for CancelPolicyResponse
   */
  export interface AsProtobufJSON {
    status: string;
  }
}

/**
 * Message implementation for portal_insurance.GetPolicyDocumentRequest
 */
export class GetPolicyDocumentRequest implements GrpcMessage {
  static id = 'portal_insurance.GetPolicyDocumentRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetPolicyDocumentRequest();
    GetPolicyDocumentRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetPolicyDocumentRequest) {
    _instance.insuranceContractId = _instance.insuranceContractId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetPolicyDocumentRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.insuranceContractId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetPolicyDocumentRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetPolicyDocumentRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.insuranceContractId) {
      _writer.writeInt32(1, _instance.insuranceContractId);
    }
  }

  private _insuranceContractId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetPolicyDocumentRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetPolicyDocumentRequest.AsObject>) {
    _value = _value || {};
    this.insuranceContractId = _value.insuranceContractId;
    GetPolicyDocumentRequest.refineValues(this);
  }
  get insuranceContractId(): number {
    return this._insuranceContractId;
  }
  set insuranceContractId(value: number) {
    this._insuranceContractId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetPolicyDocumentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetPolicyDocumentRequest.AsObject {
    return {
      insuranceContractId: this.insuranceContractId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetPolicyDocumentRequest.AsProtobufJSON {
    return {
      insuranceContractId: this.insuranceContractId
    };
  }
}
export module GetPolicyDocumentRequest {
  /**
   * Standard JavaScript object representation for GetPolicyDocumentRequest
   */
  export interface AsObject {
    insuranceContractId: number;
  }

  /**
   * Protobuf JSON representation for GetPolicyDocumentRequest
   */
  export interface AsProtobufJSON {
    insuranceContractId: number;
  }
}

/**
 * Message implementation for portal_insurance.GetPolicyDocumentResponse
 */
export class GetPolicyDocumentResponse implements GrpcMessage {
  static id = 'portal_insurance.GetPolicyDocumentResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetPolicyDocumentResponse();
    GetPolicyDocumentResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetPolicyDocumentResponse) {
    _instance.document = _instance.document || new Uint8Array();
    _instance.fileName = _instance.fileName || '';
    _instance.mimeType = _instance.mimeType || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetPolicyDocumentResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.document = _reader.readBytes();
          break;
        case 2:
          _instance.fileName = _reader.readString();
          break;
        case 3:
          _instance.mimeType = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetPolicyDocumentResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetPolicyDocumentResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.document && _instance.document.length) {
      _writer.writeBytes(1, _instance.document);
    }
    if (_instance.fileName) {
      _writer.writeString(2, _instance.fileName);
    }
    if (_instance.mimeType) {
      _writer.writeString(3, _instance.mimeType);
    }
  }

  private _document: Uint8Array;
  private _fileName: string;
  private _mimeType: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetPolicyDocumentResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetPolicyDocumentResponse.AsObject>) {
    _value = _value || {};
    this.document = _value.document;
    this.fileName = _value.fileName;
    this.mimeType = _value.mimeType;
    GetPolicyDocumentResponse.refineValues(this);
  }
  get document(): Uint8Array {
    return this._document;
  }
  set document(value: Uint8Array) {
    this._document = value;
  }
  get fileName(): string {
    return this._fileName;
  }
  set fileName(value: string) {
    this._fileName = value;
  }
  get mimeType(): string {
    return this._mimeType;
  }
  set mimeType(value: string) {
    this._mimeType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetPolicyDocumentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetPolicyDocumentResponse.AsObject {
    return {
      document: this.document ? this.document.subarray(0) : new Uint8Array(),
      fileName: this.fileName,
      mimeType: this.mimeType
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetPolicyDocumentResponse.AsProtobufJSON {
    return {
      document: this.document ? uint8ArrayToBase64(this.document) : '',
      fileName: this.fileName,
      mimeType: this.mimeType
    };
  }
}
export module GetPolicyDocumentResponse {
  /**
   * Standard JavaScript object representation for GetPolicyDocumentResponse
   */
  export interface AsObject {
    document: Uint8Array;
    fileName: string;
    mimeType: string;
  }

  /**
   * Protobuf JSON representation for GetPolicyDocumentResponse
   */
  export interface AsProtobufJSON {
    document: string;
    fileName: string;
    mimeType: string;
  }
}

/**
 * Message implementation for portal_insurance.ListInsuranceContractsResponse
 */
export class ListInsuranceContractsResponse implements GrpcMessage {
  static id = 'portal_insurance.ListInsuranceContractsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListInsuranceContractsResponse();
    ListInsuranceContractsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListInsuranceContractsResponse) {
    _instance.contracts = _instance.contracts || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListInsuranceContractsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.InsuranceContractDetail();
          _reader.readMessage(
            messageInitializer1,
            common_message008.InsuranceContractDetail
              .deserializeBinaryFromReader
          );
          (_instance.contracts = _instance.contracts || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListInsuranceContractsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListInsuranceContractsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.contracts && _instance.contracts.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.contracts as any,
        common_message008.InsuranceContractDetail.serializeBinaryToWriter
      );
    }
  }

  private _contracts?: common_message008.InsuranceContractDetail[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListInsuranceContractsResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ListInsuranceContractsResponse.AsObject>
  ) {
    _value = _value || {};
    this.contracts = (_value.contracts || []).map(
      m => new common_message008.InsuranceContractDetail(m)
    );
    ListInsuranceContractsResponse.refineValues(this);
  }
  get contracts(): common_message008.InsuranceContractDetail[] | undefined {
    return this._contracts;
  }
  set contracts(
    value: common_message008.InsuranceContractDetail[] | undefined
  ) {
    this._contracts = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListInsuranceContractsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListInsuranceContractsResponse.AsObject {
    return {
      contracts: (this.contracts || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListInsuranceContractsResponse.AsProtobufJSON {
    return {
      contracts: (this.contracts || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module ListInsuranceContractsResponse {
  /**
   * Standard JavaScript object representation for ListInsuranceContractsResponse
   */
  export interface AsObject {
    contracts?: common_message008.InsuranceContractDetail.AsObject[];
  }

  /**
   * Protobuf JSON representation for ListInsuranceContractsResponse
   */
  export interface AsProtobufJSON {
    contracts:
      | common_message008.InsuranceContractDetail.AsProtobufJSON[]
      | null;
  }
}

/**
 * Message implementation for portal_insurance.GetInsuranceContractRequest
 */
export class GetInsuranceContractRequest implements GrpcMessage {
  static id = 'portal_insurance.GetInsuranceContractRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetInsuranceContractRequest();
    GetInsuranceContractRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetInsuranceContractRequest) {
    _instance.contractId = _instance.contractId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetInsuranceContractRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.contractId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetInsuranceContractRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetInsuranceContractRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.contractId) {
      _writer.writeInt32(1, _instance.contractId);
    }
  }

  private _contractId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetInsuranceContractRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetInsuranceContractRequest.AsObject>) {
    _value = _value || {};
    this.contractId = _value.contractId;
    GetInsuranceContractRequest.refineValues(this);
  }
  get contractId(): number {
    return this._contractId;
  }
  set contractId(value: number) {
    this._contractId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetInsuranceContractRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetInsuranceContractRequest.AsObject {
    return {
      contractId: this.contractId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetInsuranceContractRequest.AsProtobufJSON {
    return {
      contractId: this.contractId
    };
  }
}
export module GetInsuranceContractRequest {
  /**
   * Standard JavaScript object representation for GetInsuranceContractRequest
   */
  export interface AsObject {
    contractId: number;
  }

  /**
   * Protobuf JSON representation for GetInsuranceContractRequest
   */
  export interface AsProtobufJSON {
    contractId: number;
  }
}
